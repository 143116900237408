import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    data() {
        return {
            loadingPayments: [],
        };
    },
    computed: {
        ...mapFields("payment", {
            paymentIds: "allIds",
            paymentIdsCached: "cacheAllIds",
        }),
        ...mapGetters("payment", {
            findPayment: "find",
        }),
    },
    methods: {
        ...mapActions("payment", {
            retrievePayment: "load",
            deletePayment: 'del',
        }),
        resolvePayment(itemId, force = false) {
            if (force || (!this.isPaymentLoaded(itemId) && !this.loadingPayments.includes(itemId))) {
                this.loadingPayments.push(itemId);
                this.retrievePayment(decodeURIComponent(itemId));
            }

            return this.findPayment(itemId);
        },
        resolvePayments(itemIds) {
            if (typeof itemIds === "undefined") {
                return [];
            }

            return itemIds.map((itemId) => {
                return this.resolvePayment(itemId);
            }).filter(e => e);
        },
        resolvePaymentItems(itemIds) {
            if (typeof itemIds === "undefined") {
                return [];
            }
            return itemIds.map((itemId) => {
                if (!this.paymentIdsCached.includes(itemId) && !this.loadingPayments.includes(itemId)) {
                    this.loadingPayments.push(itemId);
                    this.retrievePayment(decodeURIComponent(itemId));
                }
                return this.findPayment(itemId);
            }).filter(e => e);
        },
        refreshPayments(itemIds) {
            if (typeof itemIds === "undefined") {
                return [];
            }
            itemIds.forEach(itemId => this.resolvePayment(itemId, true));
        },
        getPaymentItem(paymentIri, type) {
            if (paymentIri !== null) {
                let payment = this.resolvePayment(paymentIri);
                if (payment) {
                    return payment[type];
                }
            }
            return '';
        },
        isPaymentLoaded(itemId) {
            return this.paymentIdsCached.includes(itemId);
        },
        isPaymentSetLoaded(itemIds) {
            return itemIds.every(itemId => this.isPaymentLoaded(itemId));
        },
        resolvePaymentAsync(itemId) {
            const poll = resolve => {
                if (this.isPaymentLoaded((itemId))) {
                    resolve(this.resolvePayment(itemId));
                } else {
                    this.resolvePayment(itemId);
                    setTimeout(() => poll(resolve), 400);
                }
            }
            return new Promise(poll);
        },
        resolvePaymentsAsync(itemIds) {
            const poll = resolve => {
                if (this.isPaymentSetLoaded((itemIds))) {
                    resolve(this.resolvePayments(itemIds));
                } else {
                    this.resolvePayment(itemIds);
                    setTimeout(() => poll(resolve), 400);
                }
            }
            return new Promise(poll);
        },
    }
};
