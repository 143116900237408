<template>
  <v-card flat :class="showTitle ? 'mt-5' : ''" :loading="!paymentsLoaded">
    <v-card-title v-if="showTitle">{{ $t("Payments") }}</v-card-title>
    <v-card-text v-if="paymentsLoaded && paymentObjects.length > 0">
      <v-data-table
        :headers="paymentHeaders"
        :items="paymentObjects"
        :loading="!paymentsLoaded"
        :hide-default-footer="true"
        :loading-text="$t('Loading...')"
        class="elevation-1"
        item-key="@id"
      >
        <template v-slot:item.postingDate="{ item }">
          {{ formatDateTime(item["postingDate"], "L") }}
        </template>
        <template v-slot:item.amount="{ item }">
          {{ showEuroCurrency(item["amount"]) }}
        </template>
        <template v-slot:item.bankAccount="{ item }">
          {{ getBankAccountName(item["bankAccount"]) }}
        </template>
        <div slot="item.action" slot-scope="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="paymentDialogControlEdit(props.item['@id'])"
                class="mr-2"
                small
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t("PaymentEdit") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="hasRole('ROLE_DELETE_PAYMENT')">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="handleDelete(props.item)"
                class="mr-2"
                small
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t("PaymentDelete") }}</span>
          </v-tooltip>
          <PaymentDialog
            v-model="showPaymentDialogFormEdit"
            :invoiceId="props.item['invoice']"
            :paymentItem="props.item"
            :handle-updated="onPaymentUpdated"
            :title="$t('PaymentEdit')"
            @close="paymentDialogControlEdit(props.item['@id'], true)"
          />
        </div>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="success"
        :disabled="this.defaultItemProps.invoice.paid"
        @click="paymentDialogControlNew()"
      >
        {{ $t("AddPayment") }}
      </v-btn>
      <PaymentDialog
        v-model="showPaymentDialogForm"
        :invoiceId="this.defaultItemProps.invoice['@id']"
        :invoiceTotal="this.defaultItemProps.invoice.frozenTotalGross"
        :paymentItem="{}"
        :handle-created="onPaymentCreated"
        :title="$t('AddPayment')"
        @close="paymentDialogControlNew(true)"
      />

      <span v-if="this.defaultItemProps.invoice.paid" style="font-size: 12px; align-self: flex-end; margin-left: 12px">
        {{ $t("InvoiceFullyPaid") }}
      </span>
    </v-card-actions>
  </v-card>
</template>
<script>
import { formatDateTime } from "../../utils/dates";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import PaymentMixin from "@/mixins/PaymentMixin";
import BankAccount from "@/mixins/BankAccountMixin";
import NotificationMixin from "@/mixins/NotificationMixin";
import TenantMixin from "@/mixins/TenantMixin";
import InvoiceMixin from "@/mixins/InvoiceMixin";
import PaymentDialog from "../../components/payment/Dialog";
import { showEuroCurrency } from '@/utils/money';

export default {
  name: "PaymentListAndAdd",
  components: { PaymentDialog },
  mixins: [
    PaymentMixin,
    TenantMixin,
    NotificationMixin,
    BankAccount,
    InvoiceMixin,
  ],
  props: {
    values: {
      type: Object,
      required: false,
    },
    payments: {
      type: Array,
      default: () => [],
    },
    paymentsCount: {
      type: Number,
      default: 0,
    },
    defaultItemProps: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    objectType: {
      type: String,
      required: true,
    },
    isDunnable: {
      type: Boolean,
      required: false,
      default: false,
    },
    invoice: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showPaymentDialogForm: [],
      showPaymentDialogFormEdit: [],
      paymentFormItem: {},
      paymentHeaders: [
        { 
          text: this.defaultItemProps.invoice === 'invoice' ? this.$i18n.t("PostingDate") : this.$i18n.t("outpayment"), 
          value: "postingDate", 
          width: 180 
        },
        { text: this.$i18n.t("Amount"), value: "amount", width: 120 },
        { text: this.$i18n.t("BankAccount"), value: "bankAccount", width: 320 },
        { text: this.$i18n.t("Comment"), value: "comment", width: '100%' },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  computed: {
    paymentObjects() {
      return this.resolvePaymentItems(this.payments);
    },
    paymentsLoaded() {
      return this.payments.length === this.paymentObjects.length;
    },
    ...mapFields("invoice", {
      invoiceActionHandled: "handleAction",
      invoiceHandled: "handled",
    }),
    ...mapFields("payment", {paymentDeleted: "deleted"}),
  },
  methods: {
    ...mapActions("invoice", {
      actionInvoice: "action",
      retrieveInvoice: "load",
    }),
    showEuroCurrency,
    onCreated(item) {
      this.payments.push(item["@id"]);
      this.$emit("updatePayments", this.payments);
    },
    formatDateTime,
    paymentDialogControl(close = false) {
      this.showPaymentDialogForm = !close;
    },
    getBankAccountName(id) {
      let bankAccount = this.resolveBankAccount(id);
      return bankAccount.name;
    },
    paymentDialogControlNew(close = false) {
      if (!close) {
        this.showPaymentDialogForm.push("new");
      } else {
        this.showPaymentDialogForm.splice(
          this.showPaymentDialogForm.indexOf("new"),
          1
        );
      }
    },
    paymentDialogControlEdit(id, close = false) {
      if (!close) {
        this.showPaymentDialogFormEdit.push(id);
      } else {
        this.showPaymentDialogFormEdit.splice(
          this.showPaymentDialogFormEdit.indexOf(id),
          1
        );
      }
    },
    onPaymentCreated(payment) {
      this.payments.push(payment["@id"]);
      // reload the invoice as well
      this.retrieveInvoice(this.defaultItemProps.invoice["@id"]);
    },
    deletePaymentHandler(item) {
      this.payments.splice(this.payments.indexOf(item["@id"]), 1);
      this.deletePayment(item)
    },
    handleDelete(payment) {
      this.$confirm(this.$t("Are you sure you want to delete this item?"), {
        buttonTrueText: this.$t("Delete"),
        buttonFalseText: this.$t("Cancel"),
      }).then((response) => {
        if (!response) {
          return;
        }
        this.deletePaymentHandler(payment);
      });
    },
    onPaymentUpdated(updatedPayment) {
      this.payments[
        this.payments.findIndex((el) => el["@id"] === updatedPayment["@id"])
      ] = updatedPayment;
      this.retrieveInvoice(this.defaultItemProps.invoice["@id"]);
    },
  },
  watch: {
    paymentDeleted() {
      this.retrieveInvoice(this.defaultItemProps.invoice["@id"]);
    }
  }
};
</script>
