import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    data() {
        return {
            loadingBankAccounts: [],
        };
    },
    computed: {
        ...mapFields("bankAccount", {
            bankAccountIds: "allIds",
            bankAccountIdsCached: "cacheAllIds",
        }),
        ...mapGetters("bankAccount", {
            findBankAccount: "find",
        }),
    },
    methods: {
        ...mapActions("bankAccount", {
            retrieveBankAccount: "load"
        }),
        resolveBankAccount(itemId, force = false) {
            if (force || (!this.isBankAccountLoaded(itemId) && !this.loadingBankAccounts.includes(itemId))) {
                this.loadingBankAccounts.push(itemId);
                this.retrieveBankAccount(decodeURIComponent(itemId));
            }

            return this.findBankAccount(itemId);
        },
        resolveBankAccounts(itemIds) {
            if (typeof itemIds === "undefined") {
                return [];
            }

            return itemIds.map((itemId) => {
                return this.resolveBankAccount(itemId);
            }).filter(e => e);
        },
        resolveBankAccountItems(itemIds) {
            if (typeof itemIds === "undefined") {
                return [];
            }
            return itemIds.map((itemId) => {
                if (!this.bankAccountIdsCached.includes(itemId) && !this.loadingBankAccounts.includes(itemId)) {
                    this.loadingBankAccounts.push(itemId);
                    this.retrieveBankAccount(decodeURIComponent(itemId));
                }
                return this.findBankAccount(itemId);
            }).filter(e => e);
        },
        refreshBankAccounts(itemIds) {
            if (typeof itemIds === "undefined") {
                return [];
            }
            itemIds.forEach(itemId => this.resolveBankAccount(itemId, true));
        },
        getBankAccountItem(bankAccountIri, type) {
            if (bankAccountIri !== null) {
                let bankAccount = this.resolveBankAccount(bankAccountIri);
                if (bankAccount) {
                    return bankAccount[type];
                }
            }
            return '';
        },
        isBankAccountLoaded(itemId) {
            return this.bankAccountIdsCached.includes(itemId);
        },
        isBankAccountSetLoaded(itemIds) {
            return itemIds.every(itemId => this.isBankAccountLoaded(itemId));
        },
        resolveBankAccountAsync(itemId) {
            const poll = resolve => {
                if (this.isBankAccountLoaded((itemId))) {
                    resolve(this.resolveBankAccount(itemId));
                } else {
                    this.resolveBankAccount(itemId);
                    setTimeout(() => poll(resolve), 400);
                }
            }
            return new Promise(poll);
        },
        resolveBankAccountsAsync(itemIds) {
            const poll = resolve => {
                if (this.isBankAccountSetLoaded((itemIds))) {
                    resolve(this.resolveBankAccounts(itemIds));
                } else {
                    this.resolveBankAccount(itemIds);
                    setTimeout(() => poll(resolve), 400);
                }
            }
            return new Promise(poll);
        },
    }
};
