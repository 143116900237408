<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-select
            :error-messages="bankAccountErrors"
            :label="$t('BankAccount')"
            @blur="$v.item.bankAccount.$touch()"
            @input="$v.item.bankAccount.$touch()"
            :items="bankAccounts"
            v-model="item.bankAccount"
            item-value="@id"
            item-text="name"
            required
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <InputDate
            :error-messages="dateErrors"
            :label="item.type === 'invoice' ? $t('PostingDate') : $t('outpayment')"
            required
            v-model="item.postingDate"
            @blur="$v.item.postingDate.$touch()"
            @input="$v.item.postingDate.$touch()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            :error-messages="amountErrors"
            :label="$t('Amount')"
            required
            v-model.number="item.amount"
            prefix='€'
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field :label="$t('Comment')" v-model="item.comment" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { date } from "@/validators/date";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import BankAccountMixin from "@/mixins/BankAccountMixin";
import has from "lodash/has";
import InputDate from "@/components/InputDate";
import TenantMixin from "@/mixins/TenantMixin";

export default {
  name: "PaymentForm",
  mixins: [validationMixin, BankAccountMixin, TenantMixin],
  components: { InputDate },
  props: {
    values: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    initialValues: {
      type: Object,
      default: () => {},
    },
    invoiceTotal: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    violations() {
      return this.errors || {};
    },
    bankAccounts() {
      return this.currentAccountSettings["bankAccounts"];
    },
    bankAccountErrors() {
      const errors = [];

      if (!this.$v.item.bankAccount.$dirty) {
        return errors;
      }

      has(this.violations, "bankAccount") &&
        errors.push(this.violations.bankAccount);

      !this.$v.item.bankAccount.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.item.postingDate.$dirty) {
        return errors;
      }

      has(this.violations, "date") && errors.push(this.violations.postingDate);

      !this.$v.item.postingDate.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    amountErrors() {
      const errors = [];
      if (!this.$v.item.amount.$dirty) {
        return errors;
      }

      has(this.violations, "amount") && errors.push(this.violations.amount);

      !this.$v.item.amount.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
  },
  watch: {},
  created() {
    if (this.invoiceTotal) {
      this.item.amount = this.invoiceTotal
    }
    this.item.bankAccount = this.bankAccounts.find(bankAccount => bankAccount.default)['@id'];
  },
  validations: {
    item: {
      bankAccount: {
        required,
      },
      postingDate: {
        required,
        date,
      },
      amount: {
        required,
      },
    },
  },
};
</script>
