<template>
  <v-card
    flat
    :class="showTitle ? 'mt-5' : ''"
    :loading="!dunningLettersLoaded"
  >
    <v-card-title v-if="showTitle">{{ $t("DunningLetters") }}</v-card-title>
    <v-card-text v-if="dunningLettersLoaded && dunningLetterObjects.length > 0">
      <v-data-table
        :headers="dunningLetterHeaders"
        :items="dunningLetterObjects"
        :loading="!dunningLettersLoaded"
        :hide-default-footer="true"
        :loading-text="$t('Loading...')"
        class="elevation-1"
        item-key="@id"
      >
        <template slot="item.dunningLevel.fee" slot-scope="{ item }">
          {{ item.dunningLevel.fee }} &euro;
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ formatDateTime(item["createdAt"], "L LT") }}
        </template>
        <template v-slot:item.lastSentAt="{ item }">
          {{ formatDateTime(item["lastSentAt"], "L LT") }}
        </template>
        <div slot="item.action" slot-scope="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                small
                @click="download(props.item)"
              >
                mdi-file-download
              </v-icon>
            </template>
            <span>{{ $t(props.item.type) }} herunterladen</span>
          </v-tooltip>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on"
                @click="sendDunningLetter(props.item)"
                class="mr-2"
                small
            >
              mdi-send
            </v-icon>
          </template>
          <span>{{ $t("SendDunningLetter") }}</span>
        </v-tooltip>
        </div>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="success"
        :disabled="!isDunnable"
        @click="generateDunningLetter()"
      >
        {{ $t("GenerateDunningLetter") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import { formatDateTime } from "../../utils/dates";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import DocumentMixin from "@/mixins/DocumentMixin";
import DunningLetterMixin from "@/mixins/DunningLetterMixin";
import NotificationMixin from "@/mixins/NotificationMixin";
import TenantMixin from "@/mixins/TenantMixin";

export default {
  name: "DunningLetterListAndAdd",
  components: {},
  mixins: [DunningLetterMixin, TenantMixin, DocumentMixin, NotificationMixin],
  props: {
    values: {
      type: Object,
      required: false,
    },
    dunningLetters: {
      type: Array,
      default: () => [],
    },
    dunningLettersCount: {
      type: Number,
      default: 0,
    },
    defaultItemProps: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    objectType: {
      type: String,
      required: true,
    },
    isDunnable: {
      type: Boolean,
      required: false,
      default: false,
    },
    invoice: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dunningLetterFormItem: {},
      dunningLetterHeaders: [
        { text: this.$i18n.t("DunningLevel"), value: "dunningLevel.level", width: 120 },
        { text: this.$i18n.t("Name"), value: "dunningLevel.name", width: '100%' },
        { text: this.$i18n.t("DunningFee"), value: "dunningLevel.fee", width: 200 },
        { text: this.$i18n.t("createdAt"), value: "createdAt", width: 220 },
        { text: this.$i18n.t("lastSentAt"), value: "lastSentAt", width: 220 },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  computed: {
    dunningLetterObjects() {
      return this.resolveDunningLetterItems(this.dunningLetters);
    },
    dunningLettersLoaded() {
      return this.dunningLetters.length === this.dunningLetterObjects.length;
    },
    ...mapFields("invoice", {
      invoiceActionHandled: "handleAction",
      invoiceHandled: "handled",
    }),
    ...mapFields("dunningLetter", {
      dunningLetterActionHandled: "handleAction",
      dunningLetterHandled: "handled",
    }),
  },
  methods: {
    ...mapActions("invoice", {
      actionInvoice: "action",
    }),
    ...mapActions("dunningLetter", {
      actionDunningLetter: "action",
    }),
    onCreated(item) {
      this.dunningLetters.push(item["@id"]);
      this.$emit("updateDunningLetters", this.dunningLetters);
    },
    download(dunningLetter) {
      this.getAndDownloadDocument(dunningLetter.document);
    },
    formatDateTime,
    dunningLetterDialogControl(close = false) {
      this.showDunningLetterDialogForm = !close;
    },
    generateDunningLetter() {
      this.$confirm(
        this.$t("Are you sure you want to create a dunning for this invoice?"),
        {
          buttonTrueText: this.$t("Create"),
          buttonFalseText: this.$t("Cancel"),
        }
      ).then((response) => {
        if (!response) {
          return;
        }
        this.actionInvoice({
          item: { "@id": this.defaultItemProps.invoice },
          action: "createDunningLetter",
        });
      });
    },
    sendDunningLetter(item) {
      this.$confirm(
              this.$t("DunningLetterSendText"),
          {
            buttonTrueText: this.$t("Yes"),
            buttonFalseText: this.$t("No"),
          },
      ).then((response) => {
        if (!response) {
          return;
        }
        this.actionDunningLetter({
          item,
          action: "send",
        });
        this.showMessage(this.$t("DunningLetterSent"));
      });
    },
  },
  watch: {
    invoiceHandled() {
      if (this.invoiceActionHandled === "createDunningLetter") {
        this.showMessage(this.$t("DunningCreated"));
      }
      this.invoiceActionHandled = null;
    },
    dunningLetterHandled() {
      if (this.dunningLetterActionHandled === "send") {
        this.showMessage(this.$t("DunningLetterSent"));
      }
      this.dunningLetterActionHandled = null;
    },
  },
};
</script>
