<template>
  <div>
    <Toolbar
        :title="`Rechnung #${this.item.number}`"
        :download-loading="loadingDownload"
        :handle-copy="copy"
        :handle-download="downloadPdf"
        :enable-regenerate-document="this.item.frozen"
        :handle-regenerate-document="regenerateDownloadPdf"
        :regenerate-document-loading="loadingDownload"
    />
    <v-container fluid v-if="!this.invoiceIsLoading">
      <v-row>
        <v-col cols="12" sm="6" lg="4">
          <v-card elevation="0">
            <v-card-title>Kunde</v-card-title>
            <v-card-text>
              KNr.: <strong>{{ this.customer.customerNumber }}</strong>
              <div style="white-space: pre;">{{ this.item.addressText }}</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" lg="4" offset-lg="4">
          <v-card elevation="0">
            <v-card-title>Rechnungsinfos</v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <th>Bezahlt</th>
                    <td>
                      <v-icon small v-if="item.paid">mdi-check-circle</v-icon>
                      <v-icon small v-else>mdi-close-circle</v-icon>
                    </td>
                  </tr>
                  <tr>
                    <th>Rechnungsdatum</th>
                    <td>{{ formatDateTime(item.date, "L") }}</td>
                  </tr>
                  <tr>
                    <th>Festgeschrieben am</th>
                    <td>{{ formatDateTime(item.frozenAt, "L") }}</td>
                  </tr>
                  <tr>
                    <th>Summe Netto</th>
                    <td>
                      {{ showEuroCurrency(item.frozenTotalNet) }}
                    </td>
                  </tr>
                  <tr>
                    <th>Summe Brutto</th>
                    <td>
                      {{ showEuroCurrency(item.frozenTotalGross) }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="canHavePayments">
        <v-col cols="12">
          <PaymentListAndAdd
              :default-item-props="{ invoice: this.item }"
              :payments="this.item.payments"
              :show-title="true"
              object-type="invoice"
          />
        </v-col>
        <v-col cols="12" v-if="isInvoice">
          <DunningLetterListAndAdd
              :default-item-props="{ invoice: this.item['@id'] }"
              :dunning-letters="this.item.dunnings"
              :is-dunnable="this.item.dunnable"
              :show-title="true"
              object-type="invoice"
              @updateDiscounts="updateDiscountsHandler"
          />
        </v-col>
      </v-row>
    </v-container>

    <Loading :visible="invoiceIsLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { createHelpers } from "vuex-map-fields";
import Loading from "../../components/Loading";
import Toolbar from "../../components/Toolbar";
import DocumentMixin from "@/mixins/DocumentMixin";
import InvoiceMixin from "@/mixins/InvoiceMixin";
import DunningLetterListAndAdd from "@/components/dunningLetter/ListAndAdd";
import PaymentListAndAdd from "@/components/payment/ListAndAdd";
import CustomerMixin from "@/mixins/CustomerMixin";
import ShowMixin from "@/mixins/ShowMixin";
import { showEuroCurrency } from '@/utils/money';

const servicePrefix = "Invoice";

const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField",
});

export default {
  name: "InvoiceUpdate",
  servicePrefix,
  mixins: [ShowMixin, InvoiceMixin, DocumentMixin, CustomerMixin],
  components: {
    Loading,
    Toolbar,
    DunningLetterListAndAdd,
    PaymentListAndAdd,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapFields([
      "error",
      "isLoading",
      "updated",
      "violations",
      "handled",
      "handleAction",
    ]),
    ...mapGetters("invoice", ["find"]),
    currentDocument() {
      if (!this.item.document) {
        return null;
      }
      let document = this.resolveDocument(this.item.document);
      if (typeof document === "undefined") {
        return null;
      }
      return document;
    },
    invoiceIsLoading() {
      return this.isLoading && !this.loadingDownload;
    },
    isInvoice() {
      return this.item.type === "invoice";
    },
    canHavePayments() {
      return this.item.type === "invoice" || this.item.type === "credit";
    },
    customer() {
      return this.resolveCustomer(this.item.customer)
    }
  },
  methods: {
    showEuroCurrency,
    log(m) {
      console.log(m);
    },
    ...mapActions("invoice", {
      deleteItem: "del",
      retrieve: "load",
      update: "update",
      actionInvoice: "action",
    }),
    getItemSubs() {
      return {
        profile: {},
      };
    },
    downloadPdf() {
      this.getAndDownloadDocument(this.item.document);
    },
    regenerateDownloadPdf() {
      this.$confirm("Diese Rechnung jetzt neu generieren?", {
        buttonTrueText: this.$t("Yes"),
        buttonFalseText: this.$t("No"),
      }).then((response) => {
        if (!response) {
          return;
        }
        this.regenerateInvoiceDocument(this.item);
      });
    },
    copy() {
      this.actionInvoice({ item: this.item, action: "copy" });
    },
    updateDiscountsHandler(updatedDiscounts) {
      this.item.discounts = updatedDiscounts;
    },
  },
  watch: {
    currentDocument() {
      if (this.loadingDownload && this.currentDocument !== null) {
        this.getAndDownloadDocument(this.item.document).then(() => {
          this.loadingDownload = false;
          this.updateMessage = this.originalUpdateMessage;
        });
      }
    },
    handled() {
      if (this.handleAction === "copy") {
        this.showMessage(this.$t("InvoiceCopied"));
        this.$router.push({
          name: "InvoiceUpdate",
          params: { id: this.handled["@id"] },
        });
        this.handleAction = null;
        this.handled = null;
      }
    },
  },
};
</script>
