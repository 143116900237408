import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    data() {
        return {
            loadingDunningLetters: [],
        };
    },
    computed: {
        ...mapFields("dunningLetter", {
            dunningLetterIds: "allIds",
            dunningLetterIdsCached: "cacheAllIds",
            dunningLetterHandled: 'handled',
            dunningLetterActionHandled: 'handleAction'
        }),
        ...mapGetters("dunningLetter", {
            findDunningLetter: "find",
        }),
    },
    methods: {
        ...mapActions("dunningLetter", {
            retrieveDunningLetter: "load"
        }),
        resolveDunningLetter(itemId, force = false) {
            if (force || (!this.isDunningLetterLoaded(itemId) && !this.loadingDunningLetters.includes(itemId))) {
                this.loadingDunningLetters.push(itemId);
                this.retrieveDunningLetter(decodeURIComponent(itemId));
            }

            return this.findDunningLetter(itemId);
        },
        resolveDunningLetters(itemIds) {
            if (typeof itemIds === "undefined") {
                return [];
            }

            return itemIds.map((itemId) => {
                return this.resolveDunningLetter(itemId);
            }).filter(e => e);
        },
        resolveDunningLetterItems(itemIds) {
            if (typeof itemIds === "undefined") {
                return [];
            }
            return itemIds.map((itemId) => {
                if (!this.dunningLetterIdsCached.includes(itemId) && !this.loadingDunningLetters.includes(itemId)) {
                    this.loadingDunningLetters.push(itemId);
                    this.retrieveDunningLetter(decodeURIComponent(itemId));
                }
                return this.findDunningLetter(itemId);
            }).filter(e => e);
        },
        refreshDunningLetters(itemIds) {
            if (typeof itemIds === "undefined") {
                return [];
            }
            itemIds.forEach(itemId => this.resolveDunningLetter(itemId, true));
        },
        getDunningLetterItem(dunningLetterIri, type) {
            if (dunningLetterIri !== null) {
                let dunningLetter = this.resolveDunningLetter(dunningLetterIri);
                if (dunningLetter) {
                    return dunningLetter[type];
                }
            }
            return '';
        },
        isDunningLetterLoaded(itemId) {
            return this.dunningLetterIdsCached.includes(itemId);
        },
        isDunningLetterSetLoaded(itemIds) {
            return itemIds.every(itemId => this.isDunningLetterLoaded(itemId));
        },
        resolveDunningLetterAsync(itemId) {
            const poll = resolve => {
                if (this.isDunningLetterLoaded((itemId))) {
                    resolve(this.resolveDunningLetter(itemId));
                } else {
                    this.resolveDunningLetter(itemId);
                    setTimeout(() => poll(resolve), 400);
                }
            }
            return new Promise(poll);
        },
        resolveDunningLettersAsync(itemIds) {
            const poll = resolve => {
                if (this.isDunningLetterSetLoaded((itemIds))) {
                    resolve(this.resolveDunningLetters(itemIds));
                } else {
                    this.resolveDunningLetter(itemIds);
                    setTimeout(() => poll(resolve), 400);
                }
            }
            return new Promise(poll);
        },
    }
};
