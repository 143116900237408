<template>
    <v-dialog v-model="showPaymentDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <PaymentForm
            :errors="violations"
            :values="item"
            :loading="isLoading"
            :ref="getFormRef()"
            :invoiceId="invoiceId"
            :invoiceTotal="invoiceTotal"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="showPaymentDialog = false">
            {{ $t("Close") }}
          </v-btn>
          <v-spacer/>
          <v-btn class="mainAccent lighten-3" @click="resetForm">{{ $t("Reset") }}</v-btn>
          <v-btn color="primary" @click="sendForm">{{ $t("Submit") }}</v-btn>
        </v-card-actions>
      </v-card>
      <Loading :visible="isLoading"/>
    </v-dialog>
  </template>
  
  <script>
  import {createHelpers} from "vuex-map-fields";
  import PaymentForm from "./Form";
  import NotificationMixin from "../../mixins/NotificationMixin";
  import Loading from "@/components/Loading";
  import {mapActions, mapGetters} from "vuex";
  import isEmpty from "lodash/isEmpty";
  
  const servicePrefix = "Payment";
  
  const {mapFields} = createHelpers({
    getterType: "payment/getField",
    mutationType: "payment/updateField",
  });
  
  export default {
    name: "PaymentDialog",
    servicePrefix,
    mixins: [NotificationMixin],
    components: {
      Loading,
      PaymentForm,
    },
    props: {
      value: Array,
      title: {
        type: String,
        required: true,
      },
      handleCreated: {
        type: Function,
        required: false,
      },
      handleUpdated: {
        type: Function,
        required: false,
      },
      handleDeleted: {
        type: Function,
        required: false,
      },
      paymentItem: {
        type: Object,
        required: false,
      },
      invoiceId: {
        type: String,
        required: true,
      },
      invoiceTotal: {
        type: Number,
        required: false,
      },
    },
    data() {
      return {
        item: {
        },
        confirmDelete: false,
        createMessage: this.$t("successfullyCreated"),
        updateMessage: this.$t("successfullyUpdated"),
        deleteMessage: this.$t("successfullyDeleted"),
        confirmAttachmentDeletionMessage: this.$t("confirmAttachmentDeletion"),
      };
    },
    computed: {
      ...mapFields([
        "error",
        "isLoading",
        "created",
        "deleted",
        "violations",
        "updated",
        "byId",
      ]),
      ...mapGetters("payment", ["find"]),
      formRefName() {
        if (this.handleUpdated) {
          return "updateForm_" + this.paymentItem["@id"];
        }
        return "createForm";
      },
      formRef() {
        if (this.handleUpdated) {
          return this.$refs["updateForm_" + this.paymentItem["@id"]];
        }
        return this.$refs.createForm;
      },
      showPaymentDialog: {
        get() {
          return (
            this.value.indexOf(
              typeof this.paymentItem["@id"] === "undefined"
                ? "new"
                : this.paymentItem["@id"]
            ) > -1
          );
        },
        set(value) {
          if (!value) {
            this.$emit("close");
          }
        },
      },
    },
    methods: {
      ...mapActions("payment", {
        create: "create",
        update: "update",
        resetData: "resetData",
        retrieve: "load",
        deleteItem: "del",
      }),
      sendForm() {
        if (this.isValidForm()) {
          let model = this.formRef.$v.item.$model;
          if (this.handleUpdated) {
            this.update(model);
          }
          if (this.handleCreated) {
            model["invoice"] = this.invoiceId;
            this.create(model);
          }
        }
      },
      reset() {
        this.formRef.$v.$reset();
      },
      resetForm() {
        this.item = {description: null};
        this.formRef.$v.$reset();
      },
      del() {
        this.deleteItem(this.paymentItem).then(() => {
          this.showPaymentDialog = false;
          this.showMessage(this.deleteMessage);
          this.handleDeleted(this.paymentItem);
        });
      },
      isValidForm() {
        this.formRef.$v.$touch();
        return !this.formRef.$v.$invalid;
      },
      getFormRef() {
        return this.formRefName;
      },
    },
    watch: {
      created(created) {
        if (!created) {
          return;
        }
        this.showMessage(this.createMessage);
        if (this.handleCreated) {
          this.handleCreated(this.created);
          this.resetForm();
          this.showPaymentDialog = false;
        }
      },
      updated() {
        this.showMessage(this.updateMessage);
        if (this.handleUpdated) {
          this.handleUpdated(this.updated);
          this.showPaymentDialog = false;
        }
      },
      error(message) {
        message && this.showError(message);
      },
      deleteError(message) {
        message && this.showError(message);
      },
    },
    created() {
      this.item = !isEmpty(this.paymentItem)
        ? {...this.paymentItem}
        : {...this.item};
    },
  };
  </script>
  